<template>
  <div>
    <FilterImpianti
      @getImpianti="searchImpianti"
      @resetFilters="resetFilters"
    />
    <TableImpianti @getImpianti="searchImpianti" @resetFilters="resetFilters" />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import TableImpianti from "@/components/components-fit/impianti/TableImpianti.vue";
import FilterImpianti from "@/components/components-fit/impianti/FiltriImpianti.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

export default defineComponent({
  name: "ImpiantiList",
  components: {
    TableImpianti,
    FilterImpianti,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Impianti", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const id_provincia = computed(() => store.getters.id_provincia);
    const id_comune = computed(() => store.getters.id_comune);
    const denominazione = computed(() => store.getters.denominazione);
    const stato_omologazione = computed(() => store.getters.stato_omologazione);
    const rowsToSkipImpianti = computed(() => store.getters.rowsToSkipImpianti);
    const fetchRowsImpianti = computed(() => store.getters.fetchRowsImpianti);
    const sortColumnImpianti = computed(() => store.getters.sortColumnImpianti);
    const sortOrderImpianti = computed(() => store.getters.sortOrderImpianti);
    const ente_aggregato = computed(() => store.getters.ente_aggregatoImpianti);

    const getImpianti = () => {
      const localKeys = {
        id_provincia: id_provincia.value,
        id_comune: id_comune.value,
        denominazione: trimInput(denominazione.value),
        stato_omologazione: stato_omologazione.value,
        rowsToSkip: rowsToSkipImpianti.value,
        fetchRows: fetchRowsImpianti.value,
        sortColumn: sortColumnImpianti.value,
        sortOrder: sortOrderImpianti.value,
        ente_aggregato: ente_aggregato.value,
      };
      /*  */
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.IMPIANTI_LIST,
        itemName: "impianti_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersImpianti");
      getImpianti();
    };
    getImpianti();

    const searchImpianti = () => {
      getImpianti();
    };

    return {
      getImpianti,
      resetFilters,
      searchImpianti,
    };
  },
});
</script>
