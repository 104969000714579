<template>
  <div>
    <div class="card shadow" @keyup.enter="getFilteredImpianti()">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4">
          <div class="row">
            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Denominazione"
                aria-label=""
                @input="updateDenominazione"
                :value="denominazione"
              />
            </div>
            <div class="col-sm-4 filter">
              <select
                class="form-select"
                aria-label=""
                @change="
                  resetComune();
                  setProvinciaImp($event);
                  $event.target.value != 0
                    ? setIsSelectedProvincia(true)
                    : setIsSelectedProvincia(false);
                "
                :value="id_provincia ? id_provincia : 0"
              >
                <option value="0">Provincia</option>
                <option
                  v-for="provincia in province"
                  :key="provincia.codP"
                  :value="provincia.codP"
                >
                  {{ provincia.nomeP }}
                </option>
              </select>
            </div>
            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Comune"
                aria-label=""
                @input="
                  spliceComuniIstat();
                  setComuni($event.target.value);
                "
                :value="comuneString"
                @keyup.delete="spliceComuniIstat"
                :disabled="!selectedProvincia"
                autocomplete="off"
              />
              <ListComuni
                v-if="comuniIstat"
                :list="comuniIstat"
                @hideList="spliceComuniIstat()"
                @selectedElement="
                  setComuneImp($event);
                  spliceComuniIstat();
                "
              ></ListComuni>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-6 filter justify-content-center">
              <SelectInput
                :options="omologazioni_stato_impianti"
                name="stato_omologazione"
                :value="stato_omologazione"
                placeholder="Stato omologazione"
                @changeSelect="setstato_omologazione($event)"
              />
            </div>

            <div class="col-sm-6 align-self-center text-center">
              <!-- <div class="col-sm-2 align-self-center text-center">
                <input class="form-check-input" type="checkbox" /><label
                  class="fw-bold ps-1 text-gray-800"
                >
                  Da inviare</label
                >
              </div> -->
              <div class="d-flex flex-row h-100 align-content-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label=""
                  @input="
                    updateEnteAggregato($event.target.checked ? true : false)
                  "
                  :checked="ente_aggregato"
                />
                <label class="fw-bold ps-1 text-gray-800">Ente aggregato</label>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click.prevent="getFilteredImpianti()"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click.prevent="$emit('resetFilters')"
              :disabled="!loaded"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import ListComuni from "../../../components/components-fit/utility/ListComuni.vue";
import { useStore } from "vuex";
import { defineComponent, ref, computed, getCurrentInstance } from "vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "FilterImpianti",
  props: {},
  emits: ["getImpianti", "resetFilters"],
  components: { SelectInput, ListComuni },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    let isActive = ref(false);

    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const omologazioni_stato_impianti = computed(() =>
      store.getters.getStateFromName("omologazioni_stato_impianti")
    );
    const keys = ref("ip|osi");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (
      !province.value ||
      (province.value && !province.value.length) ||
      !omologazioni_stato_impianti.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const updateDenominazione = (e) => {
      store.commit("setDenominazioneImpianti", e.target.value);
    };
    const updateIdProv = (e) => {
      store.commit("setIdProvinciaImpianti", e.target.value);
    };

    // function resetFilters() {
    //   store.commit("resetImpianti");
    //   selectedProvincia.value = false;
    //   id_provincia.value = "";
    //   comuneString.value = "";
    //   getFilteredImpianti();
    // }
    function getFilteredImpianti() {
      store.commit("setRowsToSkipImpianti");
      emit("getImpianti");
    }
    function getProvinceFiltrate(regione) {
      store.commit("setLookupProv", parseInt(regione));
      getFilteredImpianti();
    }
    function getComuniFiltrati(provincia) {
      store.commit("setLookupCom", parseInt(provincia));
      store.commit("setProvinciaImp", parseInt(provincia));
      getFilteredImpianti();
    }
    const resetComune = () => {
      store.commit("setSelectedComuneTextImpianti", "");
      store.commit("setComuneImp", null);
    };
    const setComuneImp = ({ value, name }) => {
      store.commit("setSelectedComuneTextImpianti", name);
      store.commit("setComuneImp", parseInt(value));
    };
    const selectedRegione = ref(false);
    const id_provincia = computed(() => store.getters.id_provincia);
    // const selectedProvincia = ref(id_provincia.value ? true : false);
    const selectedProvincia = computed(
      () => store.getters.selectedProvinciaImpianti
    );
    const setIsSelectedProvincia = (bool) => {
      store.commit("setIsSelectedProvincia", bool);
    };
    const comuneP = ref("");

    // const changeselectedProvinciaP = (e) => {
    //   comuneString.value = "";
    //   if (e === undefined || e === null || e === "-") {
    //     selectedProvincia.value = false;
    //     comuneP.value = "";
    //     id_provincia.value = null;
    //   } else {
    //     selectedProvincia.value = true;
    //     comuneP.value = "";
    //     id_provincia.value = e.target.value;
    //   }
    // };

    const comuneString = computed(() => store.getters.comuneTextImpianti);
    const setComuni = (text) => {
      store.commit("setSelectedComuneTextImpianti", text);

      if (text.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: text,
            codp: id_provincia.value,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    const inputFocus = ref("");

    const setProvinciaImp = (e) => {
      store.commit("setProvinciaImp", parseInt(e.target.value));
    };

    spliceComuniIstat();

    const stato_omologazione = computed(() => store.getters.stato_omologazione);

    const setstato_omologazione = (event) => {
      store.commit("setstato_omologazione", event);
    };

    const updateEnteAggregato = (e) => {
      store.commit("setEnteAggregato", e);
    };

    return {
      updateEnteAggregato,
      resetComune,
      denominazione: computed(() => store.getters.denominazione),
      //id_provincia: computed(() => store.getters.id_provincia),
      regioni: computed(() => store.getters.regioni),
      comuni: computed(() => store.getters.comuniFiltrati),
      isActive,
      getFilteredImpianti,
      // resetFilters,
      updateDenominazione,
      updateIdProv,
      selectedRegione,
      selectedProvincia,
      setIsSelectedProvincia,
      getProvinceFiltrate,
      getComuniFiltrati,
      omologazioni_stato_impianti,
      //setComune,
      province,
      // changeselectedProvinciaP,
      comuneP,
      spliceComuniIstat,
      setComuni,
      setstato_omologazione,
      stato_omologazione,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      setComuneImp,
      inputFocus,
      id_provincia,
      comuneString,
      setProvinciaImp,
      loading: computed(() => store.getters.loadingImpianti),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimpianti_list")
      ),
      ente_aggregato: computed(() => store.getters.ente_aggregatoImpianti),
    };
  },
});
</script>

<style></style>
